import Image from "next/image";
import styles from './FeaturedBlogs.module.css';
import {IBlogPost} from '../../interfaces';
import {NavLink} from '../ui';
import { CountryCode } from '../../lib/constants';
import { NextRouter, useRouter } from "next/router";
import { cmsLoader } from "../../lib/helpers/imageLoader";
import { formatDate, pushRoute } from "../../lib/helpers/common";

type Props = {
    latestPosts:IBlogPost[],
    countryCode:CountryCode,
    h2Text: string,
    pText: string
  }

export function FeaturedBlogS({latestPosts, countryCode, h2Text, pText}:Props){
    const router = useRouter()
    if(!latestPosts || latestPosts.length==0)
        return null;
    const siteURL=process.env.SITE_URL+"/"+countryCode;              
    return (
        <section className="section blogSection">
            <div className="container">
                <h2 className={`${styles.heading} text222 f500 mb12`}>{h2Text}</h2>
                <p className={`${styles.subHeading} mb46 font16`}>{pText}</p>
                <div className={styles.blogTaile}>
                    <div className={styles.blogLeft}>
                   <figure onClick={()=>{pushRoute(siteURL+"/trends/"+latestPosts[0].parmalink)}}><Image
                        width={374}
                        height={250}
                        loader={cmsLoader}
                        src={latestPosts[0].post_relative}
                        alt={latestPosts[0].post_cat_name.name}
                        className="imgFluid"
                         /></figure>
                        <div className={styles.blogLeftBody}>
                            <NavLink href={siteURL+"/trends/"+latestPosts[0].post_cat_name.url} ><span className={`${styles.blogTag} mb10 font12 textpink dIlineBlock`}>{latestPosts[0].post_cat_name.name}</span></NavLink>
                            <NavLink href={siteURL+"/trends/"+latestPosts[0].parmalink} ><span className="f500 font16 mb10  displayBlock text222 textEllpise fontSM14">{latestPosts[0].post_title}</span></NavLink>
                             <p className="font14 text666  lineClamp" onClick={()=>pushRoute(siteURL+"/trends/"+latestPosts[0].parmalink)}>{latestPosts[0].post_content.slice(0,200)}</p>
                        </div>
                    </div>
                    <div className={styles.blogCenter}>
                        <div className={`${styles.blogMidTile} gridSpan2`}>
                            <div className={`${styles.midBlogtext} textfff`}>
                                <NavLink href={siteURL+"/trends/"+latestPosts[1].post_cat_name.url} ><span className="bgGray textpink">{latestPosts[1].post_cat_name.name}</span></NavLink>
                                <NavLink href={siteURL+"/trends/"+latestPosts[1].parmalink} ><p className="textEllpise textfff">{latestPosts[1].post_title}</p></NavLink>
                            </div>
                                <div className={styles.imgBx} onClick={()=>{pushRoute(siteURL+"/trends/"+latestPosts[1].parmalink)}}>
                                    <Image
                                        width={359}
                                        height={188}
                                        loader={cmsLoader}
                                        src={latestPosts[1].post_relative}
                                        alt={latestPosts[1].post_cat_name.name}
                                        className="imgFluid"
                                         />
                                </div>
                        </div>
                        <div className={`${styles.blogMidTile} ${styles.midFix}`}>
                            <div className={styles.midBlogtext}>
                                <NavLink href={siteURL+"/trends/"+latestPosts[2].post_cat_name.url} ><span className="bgGray textpink">{latestPosts[2].post_cat_name.name}</span></NavLink>
                                <NavLink href={siteURL+"/trends/"+latestPosts[2].parmalink} ><p className="textEllpise textfff">{latestPosts[2].post_title}</p></NavLink>
                            </div>
                            <div className={styles.imgBx} onClick={()=>{pushRoute(siteURL+"/trends/"+latestPosts[2].parmalink)}}>
                                <Image
                                    width={168}
                                    height={175}
                                    loader={cmsLoader}
                                    src={latestPosts[2].post_relative}
                                    alt={latestPosts[2].post_cat_name.name}
                                    className="imgFluid"
                                     />
                            </div>
                        </div>
                        <div className={`${styles.blogMidTile} ${styles.midFix}`}>
                            <div className={styles.midBlogtext}>
                            <NavLink href={siteURL+"/trends/"+latestPosts[3].post_cat_name.url} ><span className="bgGray textpink">{latestPosts[3].post_cat_name.name}</span></NavLink>
                                <NavLink href={siteURL+"/trends/"+latestPosts[3].parmalink} ><p className="textEllpise textfff">{latestPosts[3].post_title}</p></NavLink>
                            </div>
                            <div className={styles.imgBx} onClick={()=>{pushRoute(siteURL+"/trends/"+latestPosts[3].parmalink)}}>
                                <Image
                                    width={168}
                                    height={175}
                                    loader={cmsLoader}
                                    src={latestPosts[3].post_relative}
                                    alt={latestPosts[3].post_cat_name.name}
                                    className="imgFluid"
                                     />
                            </div>
                        </div>
                    </div>
                    <div className={styles.blogRightList}>
                    {
                        latestPosts.slice(4).map((b,idx)=>LatestPosts(siteURL, b,router,idx))
                    }
                    </div>
                </div>
            </div>
        </section>
    );
}

function LatestPosts(siteURL:string, latestPosts : IBlogPost,router:NextRouter,idx:number){
    return (
        <div key={idx} className={styles.blogSmList}>
           <figure onClick={()=>{pushRoute(siteURL+"/trends/"+latestPosts.parmalink)}}><Image
                width={100}
                height={100}
                loader={cmsLoader}
                src={latestPosts.post_relative}
                alt={latestPosts.post_cat_name.name}
                className="imgFluid"
                 /></figure>
            <div className={styles.rightBlogDetails}>
                <NavLink href={siteURL+"/trends/"+latestPosts.post_cat_name.url} > <span className={`${styles.tag} bgGray textpink`}>{latestPosts.post_cat_name.name}</span></NavLink>
                <NavLink href={siteURL+"/trends/"+latestPosts.parmalink} ><p className="font16 text222 f500 lineClamp">{latestPosts.post_title}</p></NavLink>
                <span className="date font12 text666">{formatDate(latestPosts.post_updated_date === latestPosts.post_published_date ? latestPosts.post_published_date : latestPosts.post_updated_date)}</span>
            </div>
        </div>
    );
}